<template>
  <main class="main">
    <div class="container fluid">
      <section class="row registration my-0">
        <div class="col-lg-6 col-md-12 registration__left">
          <h2 class="registration__title">Payment information</h2>
          <form class="registration__form" autocomplete="off">
            <div class="registration__form-input">
              <input
                v-model="paymentInfo.firstName"
                type="text"
                placeholder="First name *"
                class="mr-4"
              />
              <input
                v-model="paymentInfo.lastName"
                type="text"
                placeholder="Last name *"
              />
            </div>
            <input
              v-model="paymentInfo.phone"
              class="col-12 registration__input"
              type="number"
              placeholder="Phone *"
            />
            <input
              v-model="paymentInfo.email"
              class="col-12 registration__input"
              type="email"
              placeholder="Email address *"
            />
            <div class="registration__checkbox">
              <input type="checkbox" id="check" v-model="deliveryEnabled" />
              <label for="check" class="mb-0">Enter delivery address</label>
            </div>
            <div v-if="deliveryEnabled" class="registration__delivery-info">
              <label for="country" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Страна</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <!-- <input
                  v-model="deliveryInfo.country"
                  id="country"
                  type="text"
                  placeholder="Страна"
                /> -->
                <v-autocomplete
                  id="country"
                  item-value="id"
                  item-text="country"
                  :items="countriesList"
                  class="ma-0 pa-0"
                  @change="getDeliveryPrice()"
                  v-model="deliveryInfo.country"
                ></v-autocomplete>
              </label>
              <label for="address" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Адрес</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.address"
                  id="address"
                  type="text"
                  placeholder="Номер дома и название улицы"
                />
              </label>
              <label for="living-area" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label"
                    >Населённый пункт</span
                  >
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.livingArea"
                  id="living-area"
                  type="text"
                />
              </label>
              <label for="district" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Область/регион</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.district"
                  id="district"
                  type="text"
                />
              </label>
              <label for="mail-index" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label"
                    >Почтовый индекс</span
                  >
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.mailIndex"
                  id="mail-index"
                  type="text"
                />
              </label>
              <label
                v-if="additionalField"
                for="inn"
                class="d-flex flex-column mb-2"
              >
                <p class="mb-1">
                  <span class="delivery-info__input-label">ИНН</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <!-- v-model="deliveryInfo.inn" -->
                <input
                  id="inn"
                  type="number"
                  maxlength="12"
                  v-model="deliveryInfo.inn"
                  @input="innInput"
                />
              </label>
            </div>
          </form>
        </div>
        <div class="col-lg-6 col-md-12 registration__right">
          <h2 class="registration__title">Your order</h2>
          <div class="registration__payment">
            <h3>If you have a coupon code, please apply it below</h3>
            <input
              class="payment__promocode"
              type="text"
              placeholder="Have a promocode / dicount card number?"
            />
            <button class="payment__btn">Apply coupon</button>
            <v-expansion-panels class="mb-1" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="payment__product mb-0">
                  <span>Product</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-for="item in order"
                    :key="item.cartId"
                    class="payment__goods px-0"
                  >
                    <span
                      >{{ item?.product.name.ru }} ×
                      {{ item?.size?.count }}</span
                    >
                    <span class="price">{{ item?.size?.price?.view }}</span>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="payment__total-wrapper">
              <!-- <div class="payment__subtotal">
                <span>Subtotal</span>
                <span class="subtotal-price">$80</span>
              </div>
              <div class="payment__shipping">
                <span>Shipping</span>
                <span class="shipping-price">$20</span>
              </div> -->
            </div>
            <div class="payment__total" v-show="deliveryPrice">
              <span>Delivery</span>
              <span class="total">
                {{ order[0].size.currency.currency.grapheme }}
                {{ deliveryPrice?.delivery_view }}
              </span>
            </div>
            <div class="payment__total">
              <span>Total</span>
              <span class="total">
                {{ order[0].size.currency.currency.grapheme }} {{ subtotal }}
              </span>
            </div>
            <div class="payment__method">
              <div>
                <input type="radio" />
                <i class="visa-icon"></i>
              </div>
              <div>
                <input type="radio" />
                <i class="mastercard-icon"></i>
              </div>
              <p>VISA | MasterCard | American Express</p>
            </div>
            <div class="payment__note">
              <p>Payment is made through the ePay QAZKOM system</p>
            </div>
            <p class="payment__text">
              By placing an order, you accept our [get_rules],
              [get_politica_konf] and [get_return_and_exchange]. You also agree
              that TUMAR will store some of your data in order to improve the
              quality of your service the next time you shop on our site.
            </p>

            <button
              :class="{ valid: isDeliveryInfoValid && isPaymentInfoValid }"
              @click="onOrder"
              class="place-order-btn"
            >
              Place order
            </button>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers, mapState, mapActions } from 'vuex'

const { mapState: mapOrderState, mapActions: mapOrderActions } =
  createNamespacedHelpers('orderHistory')
const { mapState: mapCountryState, mapActions: mapCountryActions } =
  createNamespacedHelpers('country')

export default {
  name: 'Registration',
  data() {
    return {
      paymentInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      deliveryEnabled: false,
      deliveryInfo: {
        country: '',
        address: '',
        livingArea: '',
        district: '',
        mailIndex: '',
        inn: '',
      },
      additionalField: false,
      order: [],
    }
  },
  computed: {
    ...mapOrderState(['deliveryPrice']),
    ...mapCountryState(['countriesList']),
    isPaymentInfoValid() {
      const isEmpty = Object.values(this.paymentInfo).every(
        (value) => value !== ''
      )
      if (isEmpty) {
        return true
      } else {
        return false
      }
    },
    total() {
      return this.order.reduce((acc, curr) => {
        return (acc += curr?.size?.count * curr?.size?.price?.view)
      }, 0)
    },
    subtotal() {
      const price = this.order.reduce((acc, curr) => {
        if (+curr?.size.price.discount.view) {
          return (acc +=
            (curr?.size?.price?.view - curr?.size?.price?.discount?.view) *
            curr.size.count)
        } else {
          return (acc += curr?.size?.price?.view * curr?.size?.count)
        }
      }, 0)
      return price.toFixed(2)
    },
    isDeliveryInfoValid() {
      const isFilledExceptInn = Object.entries(this.deliveryInfo).every(
        ([key, value]) => {
          return key === 'inn' || value !== ''
        }
      )
      if (isFilledExceptInn) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    'deliveryInfo.country'(val) {
      if (val === 'Россия') {
        this.additionalField = true
      } else {
        this.additionalField = false
      }
    },
  },
  methods: {
    ...mapOrderActions(['createRetailOrder', 'fetchDeliveryPrice']),
    ...mapCountryActions(['fetchCountriesList']),
    getDeliveryPrice() {
      let result = {
        country_id: null,
        products: [],
      }
      result.country_id = this.deliveryInfo.country
      this.order.forEach((i) => {
        result.products.push({
          product_id: i.size.id,
          amount: i?.size?.count,
        })
      })
      this.fetchDeliveryPrice(result)
    },
    innInput(e) {
      if (e.target.value.length >= 11) {
        this.deliveryInfo.inn = e.target.value.substring(0, 12)
        return
      }
      this.deliveryInfo.inn = e.target.value
    },
    async onOrder() {
      // if (!this.isDeliveryInfoValid && !this.isPaymentInfoValid) {
      //   return
      // }
      try {
        let userInfo = {}
        let products = []
        let body = {}
        userInfo.first_name = this.paymentInfo.firstName
        userInfo.last_name = this.paymentInfo.lastName
        userInfo.phone = this.paymentInfo.phone
        userInfo.email = this.paymentInfo.email
        userInfo.country_id = this.deliveryInfo.country
        userInfo.address = this.deliveryInfo.address
        userInfo.locality = 'locality'
        userInfo.zip_code = this.deliveryInfo.mailIndex
        userInfo.wine_region = 'wine_region'
        userInfo.tax_number = 'tax_number'
        this.order.forEach((item) => {
          products.push({
            product_id: item?.size?.id,
            amount: item?.size?.count,
            price_view: item?.size?.price?.view,
            price_raw: item?.size?.price?.raw,
            currency_id: item?.size?.currency?.currency?.id,
            discount: item?.size?.price?.discount,
          })
        })
        const response = await this.createRetailOrder({
          userInfo,
          products,
          delivery: this.deliveryPrice,
        })
        if (response.status === 200) {
          window.open(response.data.url_pay, '_blank')
        }
      } catch (error) {
        // console.error(error)
      }
    },
  },
  mounted() {
    if (this.$route.params.order?.length) {
      localStorage.setItem('retailOrder', JSON.stringify([]))
      this.order = this.$route.params.order
      localStorage.setItem(
        'retailOrder',
        JSON.stringify(this.$route.params.order)
      )
    } else {
      this.order = JSON.parse(localStorage.getItem('retailOrder'))
    }
    this.fetchCountriesList()
  },
}
</script>

<style lang="scss" scoped>
.main {
  border: none;
}
.registration__delivery-info {
  select {
    position: relative;
    &:before {
      position: absolute;
      content: '^';
      right: 20px;
      top: 0;
    }
    // appearance: auto;
    padding-right: 30px;
  }
}
.delivery-info {
  &__input-label {
    // font-family: 'Alegreya Sans';
    font-weight: 500;
    color: #423f3d;
  }
  &__input-required-sign {
    color: #ea6c6c;
  }
}
.place-order-btn {
  cursor: default;
  &.valid {
    background-color: #cfebcc;
    color: #666666;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.registration {
  &__delivery-info {
    #country {
      border: none;
    }
    .v-select__slot {
      border: 0.7px solid #dcdcdc;
      border-radius: 2px;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0 15px 14px 27px !important;
  }
  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    content: none;
  }
  .v-text-field > .v-input__control > .v-input__slot:after {
    content: none;
  }
  .v-input input {
    max-height: unset;
  }
  .v-text-field input {
    padding: 10px 30px;
  }
  .v-select:not(.v-text-field--single-line):not(.v-text-field--outlined)
    .v-select__slot
    > input {
    font-size: 14px;
    line-height: 15px;
  }
}
.v-autocomplete__content .v-select-list.v-list.v-sheet {
  background-color: #ffffff;
  border: 0.7px solid #dcdcdc;
}
</style>
